




import {Component, Prop, Vue} from 'vue-property-decorator';
import {DATE_SERVICE} from '@/shared/services/date.service';

@Component({
  name: 'rs-countdown',
})
export default class RSCountdown extends Vue {
  @Prop({required: true}) until: string;
  @Prop({required: false}) format?: string;
  @Prop({required: false, default: 1}) interval: number;

  text = '';

  countdownInterval: any;

  setText() {
    if(this.format){
      const hour = DATE_SERVICE.diff(this.until, 'hour') % 24;
      const minute = DATE_SERVICE.diff(this.until, 'minute') % 60;
      const second = DATE_SERVICE.diff(this.until, 'second') % 60;
      const date = new Date();
      date.setHours(hour, minute, second);
      this.text = DATE_SERVICE.format(date, this.format);
    }
    else {
      const secondsLeft = DATE_SERVICE.diff(this.until, 'second');
      if (secondsLeft > this.interval) {
        this.text = DATE_SERVICE.countdownText(this.until);
      } else {
        this.text = 'few moments'
      }
    }

  }

  mounted() {
    this.setText();
    this.$emit('remaining', -1);
    this.countdownInterval = setInterval(() => {
      const second = DATE_SERVICE.diff(this.until, 'second');
      this.$emit('remaining', second);
      if (second < 0) {
        clearInterval(this.countdownInterval);
        this.$emit('finished');
        return;
      }
      if(second >= 0){
        this.setText();
      }
    }, this.interval * 1000);
  }

  destroyed() {
    clearInterval(this.countdownInterval);
  }
}
