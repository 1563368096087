import dayjs, {OpUnitType, QUnitType} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

class DateService {

  constructor() {
    dayjs.extend(utc)
    dayjs.extend(timezone);
    dayjs.extend(customParseFormat);
  }

  get instance(){
    return dayjs
  }

  getTimezone(){
    return dayjs.tz.guess();
  }

  getUTCOffset(){
    const offset = dayjs().utcOffset() / 60;
    return offset < 0 ? `${offset}` : `+${offset}`;
  }

  format(date: any, format: string){
    return dayjs(date).format(format);
  }

  diff(date: any, unit: QUnitType | OpUnitType){
    return dayjs(date).diff(dayjs(), unit);
  }

  countdownText(date: any){
    const day = DATE_SERVICE.diff(date, 'day');
    const hour = DATE_SERVICE.diff(date, 'hour');
    const minute = DATE_SERVICE.diff(date, 'minute');
    const second = DATE_SERVICE.diff(date, 'second');

    if(day > 1){
      return `${day} days`;
    }
    else if(day > 0){
      return `${day} day`;
    }
    else if(hour > 0){
      return `${hour}h ${minute % 60}min`;
    }
    else if(minute > 0){
      return `${minute}min`;
    }
    else if(second > 0){
      return `${second}s`;
    }
    return 'few moments';
  }


}

export const DATE_SERVICE = new DateService();
