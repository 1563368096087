
























import Component from 'vue-class-component';
import Vue from 'vue';
import RsModal from '@/shared/components/modal/modal.vue';
import {Prop} from 'vue-property-decorator';
import {AVAILABILITY} from '@/shared/constants/AVAILABILITY';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Icons} from '@/shared/components/icon/icons';

@Component({
  name: 'rs-dashboard-job-status-icon',
  components: {RsIcon, RsModal},
})
export default class DashboardJobStatusIcon extends Vue{
  @Prop() type: AVAILABILITY;
  @Prop() disabled: boolean;

  AVAILABILITY = AVAILABILITY;
  Icons = Icons;

  get status(): string{
    return {
      [AVAILABILITY.ACTIVELY_LOOKING]: 'success',
      [AVAILABILITY.OPEN]: 'warning',
      [AVAILABILITY.NOT_AVAILABLE]: 'danger',
    }[this.type || AVAILABILITY.ACTIVELY_LOOKING];
  }
}
