
















































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {Action, Getter} from 'vuex-class';
import {QuizActions} from '@/modules/quiz/store/actions';
import {QuizGetters} from '@/modules/quiz/store/getters';
import QuizModule from '@/modules/quiz';
import RsButton from '@/shared/components/buttons/button.vue';
import {UUID} from '@/shared/constants/UUID';
import {Watch} from 'vue-property-decorator';
import {IApiTalentQuizCategoryResult} from '@/shared/models/IApiTalentQuizCategoryResult';
import {QUIZ_SERVICE} from '@/shared/services/quiz.service';
import {QUIZ_STATES} from '@/shared/constants/QUIZ_STATES';
import {QuizRouter} from '@/modules/quiz/router';
import {AssesmentsRouter} from '@/modules/dashboard/modules/assesments/router';
import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import RsQuizHeaderResults from '@/modules/quiz/components/main/header-results.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import RsCountdown from '@/shared/components/countdown/countdown.vue';
import {Icons} from '@/shared/components/icon/icons';

@Component({
  components: {RsCountdown, RsIcon, RsQuizHeaderResults, RsButton},
})
export default class QuizQuiz extends Vue {
  @Action(QuizActions.CONTINUE_QUIZ, {namespace: QuizModule.namespace}) continueQuiz: (id: UUID) => void;

  @Getter(QuizGetters.GET_QUIZZES, {namespace: QuizModule.namespace}) quizzes: IApiTalentQuiz[];
  @Getter(QuizGetters.GET_RESULTS, {namespace: QuizModule.namespace}) results: IApiTalentQuizCategoryResult[];

  AssesmentsRouter = AssesmentsRouter;
  Icons = Icons;

  id: UUID | null = null;
  selected = 0;

  @Watch('quizzes')
  onQuizzesLoad(quizzes: IApiTalentQuiz[] | null) {
    if (quizzes?.length) {
      const quiz = this.quizzes.find((q) => q.quizId === this.id);
      if (quiz) {
        const state = QUIZ_SERVICE.quizState(quiz);
        if (state === QUIZ_STATES.PROGRESS && quiz.userQuizId) {
          this.continueQuiz(quiz.userQuizId);
          this.$router.push({name: QuizRouter.QUESTION, params: this.$route.params});
        } else if (state === QUIZ_STATES.AVAILABLE) {
          this.$router.push({name: QuizRouter.QUIZ, params: this.$route.params});
        } else if (state === QUIZ_STATES.PASSED && quiz.userQuizId) {
          this.continueQuiz(quiz.userQuizId);
        }
      } else {
        this.$router.push({name: AssesmentsRouter.INDEX})
      }
    }
  }

  get quiz(): IApiTalentQuiz | null {
    const quizzes = this.quizzes;
    if (quizzes) {
      return quizzes.find((q) => q.quizId === this.id) || null;
    }
    return null;
  }

  mounted() {
    this.id = this.$route.params.quizId;
  }
}
