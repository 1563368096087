













import Component from 'vue-class-component';
import Vue from 'vue';
import RsModal from '@/shared/components/modal/modal.vue';
import {Getter} from 'vuex-class';
import {OnboardingGetters} from '@/modules/onboarding/store/getters';
import OnboardingModule from '@/modules/onboarding';
import {IApiTalent} from '@/shared/models/IApiTalent';
import RsDashboardJobStatusIcon from '@/modules/dashboard/components/global/job-status/job-status-icon.vue';
import {AVAILABILITY} from '@/shared/constants/AVAILABILITY';

@Component({
  name: 'rs-dashboard-job-status-header',
  components: {RsDashboardJobStatusIcon, RsModal},
})
export default class DashboardJobStatusHeader extends Vue{
  @Getter(OnboardingGetters.GET_USER, {namespace: OnboardingModule.namespace}) user: IApiTalent;

  get status(): string{
    return {
      [AVAILABILITY.ACTIVELY_LOOKING]: 'success',
      [AVAILABILITY.OPEN]: 'warning',
      [AVAILABILITY.NOT_AVAILABLE]: 'danger',
    }[this.user.properties.AVAILABILITY || AVAILABILITY.ACTIVELY_LOOKING];
  }
}
